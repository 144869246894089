import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {UserType} from "../di-chat-adapter";
import type {Theme} from "di-chat-model";

export interface AppState {
  license: string;
  userType: UserType;
  theme: Theme;
  fontSize: string;
  isMaximized: boolean;
  isOpened: boolean;
  isMobile: boolean;
}


const initialState: AppState = {
  license: "",
  userType: "user",
  theme: "default",
  fontSize: "1rem",
  isMaximized: false,
  isOpened: false,
  isMobile: false
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLicense: (state, action: PayloadAction<string>) => {
      state.license = action.payload;
    },
    setUserType: (state, action: PayloadAction<UserType>) => {
      state.userType = action.payload;
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload
    },
    setFontSize: (state, action: PayloadAction<string> ) => {
      state.fontSize = action.payload;
    },
    setIsMaximized: (state, action: PayloadAction<boolean>) => {
      state.isMaximized = action.payload
    },
    setIsOpened: (state, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLicense, setUserType, setTheme, setFontSize, setIsMaximized, setIsOpened, setIsMobile } = appSlice.actions

export default appSlice.reducer