import {useCallback, useEffect, useRef, useState} from "react";
import {useChat, User, Presence, UserStatus} from "@chatscope/use-chat";
import type {DiChatAdapter, UserType} from "../../../di-chat-adapter";
import {nanoid} from "nanoid";
import {useGetLicense} from "./useGetLicense";
import {licenseSelector} from "../../../redux/selectors";
import {useSelector, useDispatch } from "react-redux";
import {setUserType} from "../../../redux/appSlice";

const id = nanoid();

const user = {
  id,
  firstName: "",
  lastName: "",
  email: "",
  bio: "",
  username: "",
  avatar: "",
  presence: new Presence({
    status: UserStatus.Available,
    description: ""
  }),
}

enum InitStage {
  None,
  ServiceInitialized,
  TokenNotReceived,
  LoggedIn,
  ConversationsReceived,
  Ready
}

const getServerUrl = () => process.env.REACT_APP_WIDGET_SERVER_URL ?? ""; 
const getApiUrl = () => process.env.REACT_APP_WIDGET_API_URL ?? "";
const getUserName = () => process.env.REACT_APP_WIDGET_USER_NAME ?? "";
const getUserType = ():UserType => "widget";

export const useInitializeChatEffect = () => {
  
  const receiving = useRef(false);
  const [initStage, setInitStage] = useState<InitStage>(InitStage.None);
  const [initialized, setInitialized] = useState(false);
  
  const dispatch = useDispatch();
  
  const { service , setCurrentUser, setActiveConversation, activeConversation} = useChat();

  useGetLicense();
  
  const license = useSelector(licenseSelector);
  
  const initializeService = useCallback(async () => {

    if (license.length > 0) {

      const userType = getUserType();
      
      dispatch(setUserType(getUserType()));
      
      const serverUrl = getServerUrl();
      const apiUrl = getApiUrl();
      
      const chatService = (service as unknown as DiChatAdapter);
      await chatService.init({
        server: serverUrl,
        api: apiUrl,
        autoAccept: userType === "user" // Agent akceptuje automatycznie rozmowy
      });
      console.debug("[initializeService] Set state to ServiceInitialized");
      setInitStage(InitStage.ServiceInitialized);
    } else {
      console.error("Licencja nie została ustawiona");
    }
  },[license, service, dispatch]);

  const register = useCallback(async () => {
    
      // W zależności od licencji zmieniamy usera, na którego się loguję     
      const username = getUserName();
      
      const chatService = (service as unknown as DiChatAdapter);
      const registerResponse = await chatService.register({
        id: user.id,
        username,
        avatar: user.avatar,
        status: user.presence.status,
      });

      console.debug("[initializeService] Register response", registerResponse);

      setCurrentUser(new User({

        id: registerResponse.result.id,
        username: registerResponse.result.username || user.username,
        avatar: registerResponse.result.avatar || user.avatar,
        email:"",
        firstName:"",
        lastName:"",
        bio:"",
        presence: new Presence({
          status: registerResponse.result.status,
          description: ""
        })
      }));
      
      const userType = getUserType();
      
      if ( userType === "widget" ) {
        if (registerResponse.inHours === true) {
          setInitStage(InitStage.LoggedIn);
        } else {
          setInitStage(InitStage.Ready);
        }
      } else {
        setInitStage(InitStage.LoggedIn);
      }
    },
    [service, setCurrentUser],
  );
  
  
  /**
   * - Pobiera token
   * - Łączy się
   * - Pobiera dane użytkownika
   * - Rejestruje
   * - Ustawia aktualnego użytkownika
   */
  const getToken = useCallback( async () => {

    const chatService = (service as unknown as DiChatAdapter);
    
    // W zależności od licencji zmieniamy usera, na którego się loguję     
    const username = getUserName();
    
    // Pobieram token,
    // jeżeli go mam, to mogę się zarejestrować
    const getTokenResponse = await chatService.getToken(`?license=${username}`);
    
    if ( getTokenResponse.result ) {
            
      await chatService.connect();
      await register();
      
    } else {
      
      setInitStage(InitStage.TokenNotReceived);
    }
  },[service, register]);
  
  const login = useCallback(async () => {
    
    if (license.length > 0) {
      
      const chatService = (service as unknown as DiChatAdapter);

      // W zależności od licencji zmieniamy usera, na którego się loguję     
      const username = getUserName();
      
      const loginResponse = await chatService.login({
        username,
        password: username,
      });

      console.debug("[initializeService] Login response", loginResponse);
      
      if ( loginResponse.result === false ) {
        console.warn("Unauthorized");
        return;
      }
      
      console.debug("[initializeService]  Logged in");
      
      await chatService.connect();

      console.debug("[initializeService] Websocket connected");

      await register();
      
    } else {
      console.warn("[initializeService] Missing license");
    }
  },[service, license, register]);
  

  /**
   * - Pobiera kontakty
   * - Pobiera konwersacje
   * - Ustawia aktywną konwersację
   * 
   */
  const getConversations = useCallback(async () => {
    
      const userType = getUserType();
      const chatService = (service as unknown as DiChatAdapter);
      await chatService.getContacts();

      const conversationsResponse = await chatService.getConversations();

      const cLen = conversationsResponse.items.length;
      // Tutaj zawsze powinna być tylko jedna konwersacja stworzona przy register
    if ( userType === "widget") {
      if (cLen === 1) {
        setActiveConversation(conversationsResponse.items[0].id);
        setInitStage(InitStage.ConversationsReceived);
      } else {
        console.warn(`Unexpected conversations length: ${cLen}`);
      }
    } else { // Dla usera ustawiam pierwszą jako aktywną, jeżeli jakaś jest
      if ( cLen > 0 ) {
        setActiveConversation(conversationsResponse.items[0].id);
      }
      setInitStage(InitStage.ConversationsReceived);
    }
      
  }, [service,setActiveConversation]);
  
  const getMessages = useCallback(async () => {
    
    if ( activeConversation ) {
      
    const chatService = (service as unknown as DiChatAdapter);
    const getMessagesResponse = await chatService.getMessages({conversationId: activeConversation?.id});
    
      console.debug("[initializeService] GetMessages response ", getMessagesResponse);
      setInitStage(InitStage.Ready);
      
    }
    
  }, [activeConversation, service]);
  
  useEffect(() => {
  
    // TODO: InitStage powinien być ustawiamy tutaj, a nie bezpośrednio w funkcjach, bo łatwiej później zorientować się o co chodzi
    console.debug(`UseEffect, initStage: ${initStage}`);
    if ( initStage === InitStage.None && license.length > 0 ) {
      console.debug("[initializeService] InitStage: None");
      initializeService();   
    } else if ( initStage === InitStage.ServiceInitialized ) {
      console.debug("[initializeService] InitStage: ServiceInitialized");
      getToken();
    } else if (initStage === InitStage.TokenNotReceived ) {
      console.debug("[initializeService] InitStage: TokenNotReceived");
      login();
    } else if (initStage === InitStage.LoggedIn ) {
      console.debug("[initializeService] InitStage: LoggedIn");
      if ( receiving.current === false ) {
        receiving.current = true;
        getConversations().then(() => receiving.current = false);
      }
    } else if (initStage === InitStage.ConversationsReceived ) {
      console.debug("InitStage: ConversationsReceived");
      setInitStage(InitStage.Ready);
    } else if ( initStage === InitStage.Ready ) {
      console.debug("[initializeService] InitStage: Ready");
      setInitialized(true);
    }
    
  },[initStage, license, initializeService, getToken, getConversations, login, getMessages]);
  
  
  return initialized;
  
};