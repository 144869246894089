import {setTheme,} from "../tools/setTheme";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {themeSelector, isMobileSelector, isMaximizedSelector} from "../redux/selectors";
import {useAppSelector} from "../redux/hooks";

export const useSetTheme = ({enabled}:{enabled:boolean}) => {
  
  const theme = useSelector(themeSelector);
  
  const isMobile = useAppSelector(isMobileSelector);
  const isMaximized = useAppSelector(isMaximizedSelector);
  
  useEffect(() => {
    if ( enabled ) {
      setTheme(theme, isMobile, isMaximized);
    }
  }, [theme, isMobile, enabled, isMaximized]);
  
}