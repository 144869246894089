import React from 'react';
import {AppWindow} from "./components/AppWindow";
import {ChatPage} from "./components/Chat/ChatPage";
import {HelpPage} from "./components/Help/HelpPage";
import './themes/default/main.scss';
import {useSetTheme} from "./hooks/useSetTheme";
import {useInitializeChatEffect} from "./components/Chat/hooks/useInitializeChatEffect";
import {useGetMediaQueryFromLoaderEffect} from "./hooks/useGetMediaQueryFromLoader";
import { Routes, Route } from "react-router-dom";

const App = () => {
  
  const initialized = useInitializeChatEffect();

  useGetMediaQueryFromLoaderEffect({enabled: initialized});
  
  useSetTheme({enabled: initialized});
  
  if ( initialized ) {
    return (
      <Routes>
        <Route path="/" element={<AppWindow component={<ChatPage />} />} />
        <Route path="/pomoc" element={<AppWindow component={<HelpPage />} />} />
      </Routes>
    );
  } else {
    return null;
  }
}

export default App;
