import {ReactNode, useCallback, useEffect} from "react";
import DefaultChatIcon from "./DefaultChatIcon/DefaultChatIcon";
import {Widget} from "./Widget/Widget";
import {getOpenedStyles, getClosedStyles, sendStylesToApi, getMaximizedStyles} from "../api/api";
import {isMaximizedSelector, isMobileSelector, isOpenedSelector} from "../redux/selectors";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {setIsOpened} from "../redux/appSlice";

export const AppWindow = ({component}:{component: ReactNode}) => {

  const opened = useAppSelector(isOpenedSelector);
  const maximized = useAppSelector(isMaximizedSelector);
  const dispatch = useAppDispatch();
  
  const open = useCallback(() => dispatch(setIsOpened(true)),[dispatch]);
  
  const isMobile = useAppSelector(isMobileSelector);

  useEffect(() => {
    if ( opened ) {
      sendStylesToApi(getOpenedStyles(isMobile));
    } else {
      sendStylesToApi(getClosedStyles(isMobile));
    }
    
    if ( maximized && opened) {
      sendStylesToApi(getMaximizedStyles());
    }
    
  }, [isMobile, opened, maximized]);

  
  // Łapiemy komunikaty API przesłane ze strony hostującej
  useEffect(() => {

    const onMessage = (evt:MessageEvent) => {
      if ( "command" in evt.data ) {
        if ( evt.data.command === "widget.open" ) {
          open();
          sendStylesToApi(getOpenedStyles(isMobile));
        }
      }
    };
    
    window.addEventListener("message", onMessage);

    return () => window.removeEventListener("message", onMessage);

  },[open, isMobile]);

  useEffect(() => {
    // Po zamknięciu widgeta ustawiamy focus na aktywator
    // Nie może zostac na klikniętym przycisku, bo go nie widać
    if (!opened) {
      const activator = document.getElementById("widget-activator");
      activator?.focus();
    }
  }, [opened]);
  
    return (
      <>
        {opened === false && <DefaultChatIcon onClick={open}/>}
        {opened === true && <Widget component={component} />}
      </>
    );
    
};

export default AppWindow;
