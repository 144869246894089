import {ReactNode} from "react";
import {HelmetProvider} from "react-helmet-async";
import {TooltipProvider} from "../Chat/TooltipProvider";

export interface WidgetProps {
  component: ReactNode;
}

export const Widget = ({component}: WidgetProps) => {
  return (
    <div className="position-absolute bg-white" style={{left: 0, right: 0, top: 0, bottom: 0}}>
      <HelmetProvider>
        <TooltipProvider>
          {component}
        </TooltipProvider>
      </HelmetProvider>
    </div>
  );
};