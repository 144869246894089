import {themes} from "../themes/themes";
import type {Theme} from "di-chat-model";

const root = document.documentElement;

export const setTheme = (theme:Theme, isMobile: boolean, isMaximized:boolean) => {
  
  const themeToSet = themes[theme]; 
  
  Object.keys(themeToSet).forEach( (varName:string) => {
    
    // Na mobile likwidujemy ramki aplikacji
    if ( varName === "--cs-main-container-border" && (isMobile || isMaximized)) {
      root.style.setProperty(varName, "0"); 
    } else {
      root.style.setProperty(varName, themeToSet[varName]);
    }
  });
  
}