import {Conversation, useChat, User} from "@chatscope/use-chat";
import {useMemo} from "react";
import {Avatar} from "@chatscope/chat-ui-kit-react";
import {statusMap, conversationStatusMap} from "../../../utils/statusMap";

// Ikona statusu zależy od typu konwersacji
// Dla konwersacji z normalnym uzytkownikiem zależy od stanu tego użytkownika
// Dla konwersacji z widżetem zależy od stanu konwersacji

const determineStatus = (user?:User, conversation?:Conversation) => conversation?.data.type === 1 ? conversationStatusMap[conversation?.data.state] : user ? statusMap[user?.presence.status] : undefined; 

const determineUserName = (user?:User):string => user && user.firstName && user.lastName ? `${user.firstName && user.lastName}` : user?.username ?? "";

export const useGetParticipant = (conversation?:Conversation, showStatus: boolean = true) => {
    
    const {getUser, currentUser, users} = useChat();
    
    // Memo zależy do kolekcji użytkowników, bo potrzebujemy zmienić status użytkownika etcv. po updacie otrzymanym od serwera
    // TODO: Jak pozbyć się warninga o niepotrzebnym dependency?
    return useMemo(() => {
        
        const participants = conversation?.participants.filter( p => p.id !== currentUser?.id);
        
        if (participants?.length === 1 ) { // One-on-one
          
            const user = getUser(participants[0].id);
            
            const status = showStatus ? determineStatus(user, conversation) : undefined;
            
            if ( user ) {
                return {
                    avatar: <Avatar src={user.avatar} status={status} className="me-1"/>,
                    name: determineUserName(user),
                    user
                };
            }

        } else {
          
           // Jeżeli jest widget, to może być więcej niż jeden participant (Directed conversation), więc muszę znaleźć tego, który jest widgetem           
          const widget = users?.find( u => participants?.find( p => p.id === u.id && u.data.type === "widget") );

          const status = showStatus ? determineStatus(widget, conversation) : undefined;
          
          if ( widget ) {
            return {
              avatar: <Avatar src={widget.avatar} status={status} />,
              name: determineUserName(widget),
              user: widget
            };
          }
          
        }

        return {
            avatar: undefined,
            name: undefined,
            user: undefined
        };
        
    },[conversation, currentUser, getUser, users, showStatus]);
    
}