import {HTMLAttributes, memo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faWindowMaximize, faWindowMinimize} from "@fortawesome/free-solid-svg-icons";
import {useTooltipContext} from "./TooltipProvider";
import "./window-toolbox.scss";
import {setIsMaximized, setIsOpened} from "../../redux/appSlice";
import {useDispatch} from "react-redux";
import {isMaximizedSelector, isMobileSelector} from "../../redux/selectors";
import {useCallback} from "react";
import {useAppSelector} from "../../redux/hooks";
import {getMaximizedStyles, getMinimizedStyles, sendStylesToApi} from "../../api/api";
import classNames from "classnames";

export interface WindowToolboxProps extends HTMLAttributes<HTMLDivElement> {

}

export const WindowToolbox = memo(({className, ...props}:WindowToolboxProps) => {
  
  const {useTooltips, hideTooltips} = useTooltipContext();
  
  const isMobile = useAppSelector(isMobileSelector);
  
  const {tooltips} = useTooltips('[data-window-toolbox] [data-bs-toggle="tooltip"]');
  
  const dispatch = useDispatch();
  
  const maximized = useAppSelector(isMaximizedSelector);
  
  const onCloseClick = useCallback(() => {
    dispatch(setIsOpened(false));
  },[dispatch]);
  
  const onChangeSizeClick = useCallback(() => {
    hideTooltips();
    if ( maximized ) {
      
      // Znajduję tooltip i imperatywnie zmieniam mu content 
      tooltips.find( c => c.trigger.hasAttribute("data-change-size") )?.tooltip.setContent({
        ".tooltip-inner": "Zwiększ okno"
      });
      sendStylesToApi(getMinimizedStyles());  
    } else {
      tooltips.find( c => c.trigger.hasAttribute("data-change-size"))?.tooltip.setContent({
        ".tooltip-inner": "Zmniejsz okno"
      })
      sendStylesToApi(getMaximizedStyles());
    }
    
    dispatch(setIsMaximized(!maximized));
    
  },[dispatch, maximized, tooltips, hideTooltips]);
  
  return (
    <menu className={classNames("d-flex align-items-center m-0 p-0 a11y-toolbox", className)} data-window-toolbox {...props}>
      <li className={classNames("d-flex align-items-center a11y-toolbox__app-size", {"d-none": isMobile})}>
        <button className={"btn a11y-btn"}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title={maximized ? `Zmniejsz okno` : `Zwiększ okno`}
                data-bs-custom-class="di-tooltip"
                data-change-size
                onClick={onChangeSizeClick}>
          <FontAwesomeIcon icon={maximized ? faWindowMinimize : faWindowMaximize} aria-hidden={true}/>
          <span className="visually-hidden">{maximized ? `Zmniejsz okno` : `Zwiększ okno`}</span>
        </button>
      </li>
      <li className="d-flex align-items-center a11y-toolbox__app-size">
        <button className={"btn a11y-btn window-toolbox__close-btn"}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Zamknij"
                data-bs-custom-class="di-tooltip"
                onClick={onCloseClick}
        >
          <FontAwesomeIcon icon={faTimes} aria-hidden={true}/>
          <span className="visually-hidden">Zamknij</span>
        </button>
      </li>
      
    </menu>
  );
});