import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import {store, persistor} from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {nanoid} from "nanoid";
import {AutoDraft, BasicStorage, ChatMessage, IStorage, MessageContentType, ChatProvider} from "@chatscope/use-chat";
import {DiChatAdapter} from "./di-chat-adapter";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const groupIdGenerator = () => nanoid();

const messageIdGenerator = <T extends MessageContentType>(message: ChatMessage<T>) => nanoid();

const serviceFactory = (storage: IStorage, updateState: () => void) => {
  return new DiChatAdapter(storage, updateState);
};

const externalStorage = new BasicStorage({ groupIdGenerator, messageIdGenerator});

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <React.StrictMode>
        <BrowserRouter>
          <ChatProvider serviceFactory={serviceFactory} storage={externalStorage} config={{
            typingThrottleTime: 250,
            typingDebounceTime: 8000,
            debounceTyping: true,
            autoDraft: AutoDraft.Save | AutoDraft.Restore
          }} >
            <App />
          </ChatProvider>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
