import {useEffect} from "react";
import {useAppDispatch} from "../redux/hooks";
import {setIsMobile} from "../redux/appSlice";

// This must be identical to mediaQueries in the loader configuration
// Warning! Watch out for whitespace! event returns query with whitespace regarding of query configured in the loader
const mobileQueries = [
  "(min-width: 576px)",
  "(min-width: 768px)",
  "(min-width: 992px)",
  "(min-width: 1200px)",
  "(min-width: 1400px)",
];

const host = window.parent;
const targetOrigin = "*";

export const useGetMediaQueryFromLoaderEffect = ({enabled}:{enabled:boolean}) => {
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {

    const onMessage = (evt:MessageEvent) => {
      
      const evtData = evt.data;
      const name = evtData?.name;
      const data = evtData?.data;
      if ( name === "media-query-list-change" && data) {
        
        if ( data.media ) {
          
          let isMobile;
          const idx = mobileQueries.indexOf(data.media); 
          
          if ( idx === 0 ) {
            isMobile = true;
          } else if ( idx === 1 && data.matches === false ) {
            isMobile = true;
          } else {
            isMobile = false;
          }
          
          dispatch(setIsMobile(isMobile));
          
        }
        
      }
    }

    if  (enabled) {
      window.addEventListener("message", onMessage);

      // Send request for initial mediaQuery state
      host.postMessage({command: "get-media-query", data: mobileQueries[1]}, targetOrigin)
    }
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [dispatch, enabled]);
  
}