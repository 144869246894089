import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import {SendButton} from "@chatscope/chat-ui-kit-react";
import "./help.scss";

export const HelpPage = () => {
  
  return (
    <div className="container help h-100 overflow-auto">
      
      <Helmet>
        <title>Widżet czat - Pomoc</title>
      </Helmet>
      
      <nav className="mt-4">
        <Link to="/" className="help__link">Powrót to okna czat</Link>
      </nav>
      <main className="mt-4">
        <h1 className="help__h1">Widżet czat - pomoc</h1>
        <section>
        <h2 className="help__h2">Informacje ogólne</h2>
        <p>
          Widżet czat służy do rozmowy online z naszymi konsultantami.
        </p>
          <p>Aby rozpocząć rozmowę wpisz swoje pytanie, a następnie wyślij je używając przycisku <SendButton aria-hidden="true"/><strong>Wyślij</strong> w aplikacji 
            lub klawisza <strong>ENTER</strong> na klawiaturze.</p>
          <p>Możesz od razu wysłać kilka pytań jedno po drugim.</p>
          <p>Twoje pytania zostaną skierowane do konsultanta. Zostaniesz powiadomiony, kiedy dołączy on do rozmowy.</p>
        </section>
        <section>
          <h2 className="help__h2">Redagowanie i wysyłanie wiadomości</h2>
          <p>Wiadomości możesz wpisywać w oknie tekstowym znajdującym się w dolnej części aplikacji.</p>
          <p>Okno tekstowe jest wielowierszowe, czyli pozwala na wpisanie kilku linii tekstu.</p>
          <p>Aby wprowadzić znak nowej linii:<br />
            jeżeli korzystasz z komputera użyj kombinacji klawiszy <strong>SHIFT</strong>+<strong>ENTER</strong>,<br />
            jeżeli korzystasz z klawiatury na ekranie dotykowym, użyj klawisza <strong>ENTER</strong>.
          </p>
          <p>Aby wysłać wiadomość:<br />
            jeżeli korzystasz z komputera użyj przycisku <SendButton aria-hidden="true"/><strong>Wyślij</strong> w aplikacji lub klawisza <strong>ENTER</strong> na klawiaturze,<br />
            jeżeli korzystasz z klawiatury na ekranie dotykowym, użyj przycisku <SendButton aria-hidden="true"/> <strong>Wyślij</strong> w aplikacji.
          </p>
        </section>
        <section>
          <h2 className="help__h2">Nawigacja przy użyciu klawiatury</h2>
          <p>Poruszanie się pomiędzy elementami sterującym aplikacji:<br />
            klawisz <strong>TAB</strong> - przenosi do następnego elementu,<br />
            kombinacja klawiszy <strong>SHIFT</strong> + <strong>TAB</strong> - przenosi do poprzedniego elementu.</p>
          <p>Poruszanie się pomiędzy wiadomościami w oknie wiadomości:<br />
            klawisz <strong>Strzałka w dół</strong> przenosi do następnej wiadomości,<br />
            klawisz <strong>Strzałka w górę</strong> przenosi do poprzedniej wiadomości,<br />
            klawisz <strong>Home</strong> przenosi do pierwszej wiadomości,<br />
            klawisz <strong>End</strong> przenosi do ostatniej wiadomości.</p>
        </section>
      </main>
      <nav className="mb-4">
        <Link to="/" className="help__link">Powrót do okna czat</Link>
      </nav>
  </div>
  );
  
}