export interface StyleDef {
  [key:string]: string | number
}

const targetOrigin = "*";

const host = window.parent;

export const getOpenedStyles = (mobile:boolean):StyleDef => {
  const styles:StyleDef = {
    display: "block"
  };
  if ( mobile ) {
    styles.width = "100%";
    styles.height = "100%";
    styles.top = "0";
    styles.bottom = "0";
    styles.right = "0";
    styles.left = "0";
    styles.maxHeight = "none";
  } else {
    styles.width = "400px";
    styles.height = "647px";
    styles.right = "40px";
    styles.bottom = "40px";
    styles.top = "auto";
    styles.left = "auto";
    styles.maxHeight = "calc(100% - 40px)"; // 40px must be equal with bottom
  }
  return styles;
}

export const getClosedStyles = (mobile:boolean):StyleDef => {
  const styles:StyleDef = {
    width: "90px",
    height: "90px",
    display: "block",
    maxHeight: "90px"
  };
  if ( mobile ) {
    styles.bottom = "10px";
    styles.right = "10px"
    styles.top = "auto";
    styles.left = "auto";
  } else {
    styles.top = "auto";
    styles.bottom = "40px";
    styles.right = "40px";
    styles.left = "auto";
  }
  return styles;
}

export const getMaximizedStyles = ():StyleDef => {
  const styles:StyleDef = {
    display: "block"
  };
  
    styles.width = "100%";
    styles.height = "100%";
    styles.top = "0";
    styles.bottom = "0";
    styles.right = "0";
    styles.left = "0";
    styles.maxHeight = "none";
  
    return styles;
}

export const getMinimizedStyles = ():StyleDef => {
  const styles:StyleDef = {
    display: "block"
  };
    styles.width = "400px";
    styles.height = "647px";
    styles.right = "40px";
    styles.bottom = "40px";
    styles.top = "auto";
    styles.left = "auto";
    styles.maxHeight = "calc(100% - 40px)"; // 40px must be equal with bottom
  
    return styles;
}


export const sendStylesToApi = (styles:StyleDef) => {
  host.postMessage( { command: "setStyles", styles }, targetOrigin );
}
