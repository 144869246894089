import {createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Tooltip} from "bootstrap";
import {nanoid} from "nanoid";

interface TooltipContextProps {
  useTooltips: (selector:string) => {
    tooltips: TooltipWrapper[];
  };
  hideTooltips: () => void;
}

const TooltipContext = createContext<TooltipContextProps | undefined>(undefined);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  if ( !context ) {
    throw new Error("useTooltipContext must be used within a TooltipProvider");
  }
  return context;
}


interface TooltipWrapper {
  trigger: Element;
  tooltip:Tooltip;
}

// Musimy tutaj zwrócić jako function, bo inaczej React nie uruchomi dynamicznie utworzonego hooka
const createUseTooltips = (tooltips:Map<string,TooltipWrapper[]>) => function useTooltips(selector:string) {
  
  const [currentTooltips, setCurrentTooltips] = useState<TooltipWrapper[]>([]);
  
  useEffect(() => {
    const id = nanoid();  
    const tooltipTriggerList = document.querySelectorAll(selector);
    
    const newTt = Array.from(tooltipTriggerList).map(tooltipTriggerEl => {
      return {
        trigger: tooltipTriggerEl,
        tooltip: new Tooltip(tooltipTriggerEl, {
          /* @ts-ignore */ // Bo typowanie ma złą nazwę (brakuje s)
          fallbackPlacements: ["bottom"]
        })
      }
    })
    
    tooltips.set(id, newTt);
    setCurrentTooltips(newTt);
    
    // Odmontowuje i usuwa tylko te tooltipy, które zostały utworzone w tym komponencie
    return () => {
      tooltips.get(id)?.map(t => t.tooltip.dispose());
      tooltips.delete(id);
      setCurrentTooltips([]);
    }
    
  }, [selector] );
  
  //console.log("EEEEE", id, tooltips);
  // Zwracam kolekcję zainicjalizowanych tooltipów
  return {
    tooltips: currentTooltips,
  };
  
}

export interface TooltipProviderProps {
  children:ReactNode;
}

export const TooltipProvider = ({children}:TooltipProviderProps) => {

  // TUTAJ powinien być jeszcze selector przypisany do idka
  const tooltips = useRef<Map<string,TooltipWrapper[]>>(new Map());

  const useTooltips = useRef(createUseTooltips(tooltips.current));
  
  // Chowa wszystkie tooltipy
  const hideTooltips = useCallback(() => {
      Array.from(tooltips.current.values()).forEach(t => t.forEach(t => t.tooltip.hide()));
  },[tooltips]);
  
  return (
    <TooltipContext.Provider value={{
      useTooltips: useTooltips.current,
      hideTooltips
    }}>
      {children}
    </TooltipContext.Provider>
  );
};