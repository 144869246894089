import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setLicense} from "../../../redux/appSlice";
import {licenseSelector} from "../../../redux/selectors";

export const useGetLicense = () => {

    const license = useSelector(licenseSelector);
    const dispatch = useDispatch();

    useEffect( () => {

        if ( license.length === 0 ) {
            dispatch(setLicense(window.location.hostname));
        }

    },[license, dispatch]);

};