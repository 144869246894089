import "./chat-page.scss";
import {useSelector} from "react-redux";
import {isMaximizedSelector} from "../../redux/selectors";
import classNames from "classnames";
import {Helmet} from "react-helmet-async";
import {Chat} from "./Chat";

export const ChatPage = () => {

  const maximized = useSelector(isMaximizedSelector);

  return (
    <div className="d-flex justify-content-center align-items-stretch chat-page">
      <Helmet>
        <title>Porozmawiaj z nami na czacie</title>
      </Helmet>
      <div className={classNames("chat-page__widget", {"chat-page__widget--maximized": maximized})}>
        <Chat/>
      </div>
    </div>
  );

}