import {ConversationHeader } from "@chatscope/chat-ui-kit-react";
import "./widget-conversation-header.scss";
import {A11YToolbox} from "./A11YToolbox";
import {useGetParticipant} from "./hooks/useGetParticipant";
import {useChat} from "@chatscope/use-chat";
import {useMemo} from "react";
import {WindowToolbox} from "./WindowToolbox";

interface WidgetConversationHeaderProps {
 as: string;
 onWriteClick?: () => void;
}
export const WidgetConversationHeader = ({onWriteClick}:WidgetConversationHeaderProps ) => {

    const {activeConversation} = useChat();
    const { name, avatar } = useGetParticipant(activeConversation, false);
    
    const headerText = useMemo(() => name ?
      <><span className="visually-hidden">Rozmowa z </span>{name}</> 
      : <><span className="visually-hidden">Rozmowa ze sklepem internetowym</span><span aria-hidden={true}>Porozmawiaj z nami</span></>,[name]);
    
    return (
      <ConversationHeader className="wc-header">
        
        <ConversationHeader.Content>
          <div className="d-flex align-items-center justify-content-start" >
            <div className="position-absolute wc-header__dynamic-buttons">
              <button className="btn visually-hidden-focusable wc-header__btn wc-header__btn--dynamic" onClick={onWriteClick}>Napisz wiadomość</button>
            </div>
            <A11YToolbox />
            <WindowToolbox className="ms-auto"/>
          </div>
          <hr className="position-absolute" style={{left:0, right: 0, top: "46px"}} />
          <div className="d-flex align-items-center mt-4 mb-1 wc-header__contact">
            {avatar} <h1 id="remote-user-name" className="wc-header__user-name m-0 p-0 ms-2 fw-bold">{headerText}</h1>
          </div>
        </ConversationHeader.Content>
        {/*<ConversationHeader.Actions>
            <button className="btn visually-hidden-focusable me-2 wc-header__btn" onClick={onWriteClick}>Napisz wiadomość</button>               
            <button className="btn visually-hidden-focusable me-2 wc-header__btn" onClick={onGoToConversationClick}>Przejdź do okna wiadomości</button>
            <A11YToolbox />
        </ConversationHeader.Actions>*/}
      </ConversationHeader>
    );
}
